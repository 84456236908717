import * as React from 'react';
import { Hero, Seo } from '../components';
import { initGA, Event } from '../utils';

const UnauthorizedPage = ({ data }) => {
  React.useEffect(() => {
    initGA();
    Event('FD_Catalog', '', '');
  }, []);

  return (
    <>
      <Seo title='Frame Dream Homepage' />

      <Hero>
        <Hero.Image classes='hero__bg__blue' full-height={'true'}>
          <Hero.Headline>Unauthorized</Hero.Headline>
          <Hero.SubHeadline>
            Currently Unauthorized to load catalog
          </Hero.SubHeadline>
        </Hero.Image>
      </Hero>
    </>
  );
};

export default UnauthorizedPage;
